export default {
    daily_quest_hour: 'number',
    version: 'text',
    maintenance_date: 'number',
    'matchmaking_match_load_delay': 'number',
    matchmaking_trophy_limit_coop: 'number',
    matchmaking_trophy_limit_pvp: 'number',
    match_cooldown: 'number',
    match_cooldown_min: 'number',
    match_cooldown_max: 'number',
    is_pvp_active: 'checkbox',
    client_leaderboard_manipulate_active: 'checkbox',
    current_notif_ab_category: 'number',
    is_helpshift_loading_active: 'checkbox',
    is_coop_active: 'checkbox',
    is_friendly_pvp_active: 'checkbox',
    is_friendly_coop_active: 'checkbox',
    is_random_rumble_active: 'checkbox',
    is_events_rumble_active: 'checkbox',
    match_end_survey_active: 'checkbox'
}