<template>
  <div class="main-container">
    <h1 class="main-heading">
      <div>{{ $t("CONFIG") }}</div>
      <Button text="Save" :loading="isLoading" @click="() => submit()" type="info" />
    </h1>
    <div v-if="configObj" class="content-container">
      <div class="content-row-container">
        <i class="fa-brands fa-apple fa-xl"></i>
        <div class="content-row">
          <div class="content-container sub-container">
            <div
              class="content-row"
              v-for="subKey in configConstants.osSpecificValues"
              v-bind:key="subKey"
            >
              <div class="row-title">{{ $t(`DB_VALUES.ios.${subKey}`) }}</div>
              <input
                v-model="configObj.ios[subKey]"
                :type="configSchema[subKey]"
                v-if="subKey !== 'maintenance_date'"
              />
              <DatePicker
                v-if="subKey === 'maintenance_date'"
                v-model="configObj.ios[subKey]"
                mode="dateTime"
                :min-date="Date.now()"
                :model-config="{
                  type: 'number',
                  mask: 'DD/MM/YYYY HH:mm',
                }"
              >
                <template v-slot="{ inputValue, inputEvents }">
                  <input id="date3" :value="inputValue" v-on="inputEvents" />
                </template>
              </DatePicker>
              <button
                v-if="subKey === 'maintenance_date'"
                type="button"
                class="error"
                :disabled="configObj.ios[subKey] === 0"
                @click="configObj.ios[subKey] = 0"
              >
                Clear
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="content-row-container">
        <i class="fa-brands fa-android fa-xl"></i>
        <div class="content-row">
          <div class="content-container sub-container">
            <div
              class="content-row"
              v-for="subKey in configConstants.osSpecificValues"
              v-bind:key="subKey"
            >
              <div class="row-title">
                {{ $t(`DB_VALUES.android.${subKey}`) }}
              </div>
              <input
                v-model="configObj.android[subKey]"
                :type="configSchema[subKey]"
                v-if="subKey !== 'maintenance_date'"
              />
              <DatePicker
                v-if="subKey === 'maintenance_date'"
                v-model="configObj.android[subKey]"
                mode="dateTime"
                :min-date="Date.now()"
                :model-config="{
                  type: 'number',
                  mask: 'DD/MM/YYYY HH:mm',
                }"
              >
                <template v-slot="{ inputValue, inputEvents }">
                  <input id="date4" :value="inputValue" v-on="inputEvents" />
                </template>
              </DatePicker>
              <button
                v-if="subKey === 'maintenance_date'"
                type="button"
                class="error"
                :disabled="configObj.android[subKey] === 0"
                @click="configObj.android[subKey] = 0"
              >
                Clear
              </button>
            </div>
          </div>
        </div>
      </div>
      <div
        class="content-row"
        v-for="(key, index) in Object.keys(configObj)
          .filter(
            (x) =>
              !x.startsWith('_') && x !== 'ios' && x !== 'android' && x !== 'id'
          )
          .sort()"
        v-bind:key="key"
        :class="index % 2 === 0 ? 'alternate' : ''"
      >
        <div class="row-title">{{ $t(`DB_VALUES.${key}`) }}</div>
        <input
          v-model="configObj[key]"
          :type="configSchema[key]"
          v-if="key !== 'maintenance_date'"
        />
        <DatePicker
          v-if="key === 'maintenance_date'"
          v-model="configObj[key]"
          mode="dateTime"
          :min-date="Date.now()"
          :model-config="{
            type: 'number',
            mask: 'DD/MM/YYYY HH:mm',
          }"
        >
          <template v-slot="{ inputValue, inputEvents }">
            <input id="date" :value="inputValue" v-on="inputEvents" />
          </template>
        </DatePicker>
        <button
          v-if="key === 'maintenance_date'"
          type="button"
          class="error"
          :disabled="configObj[key] === 0"
          @click="configObj[key] = 0"
        >
          Clear
        </button>
        <button
          v-if="key === 'addressable_version'"
          type="button"
          class="error"
          @click="() => updateAddressableVersion()"
        >
          Update
        </button>
      </div>
      <div class="content-row">
        <div class="row-title">{{ "Client - Server Connection Mode" }}</div>
        <select v-model="configObj._match_connection_mode">
          <option
            v-for="opt in configConstants.CONNECTION_PROTOCOLS"
            v-bind:key="opt.value"
            :value="opt.value"
          >
            {{ opt.text }}
          </option>
        </select>
      </div>
    </div>
    <div class="main-heading">
      <h3>Experiment Config</h3>
      <Button
        text="Add Experiment"
        :loading="false"
        @click="addExperiment"
        type="success"
        size="sm"
      />
    </div>
    <div v-if="configObj"  style="display: grid; grid-template-columns: 1fr 1fr 1fr; width: 100%">
      <div
        v-for="(experiment, index) in configObj._experiments"
        v-bind:key="index"
        class="experiment-container"
      >
        <Button
          text="X"
          :loading="false"
          @click="() => deleteExperiment(index)"
          type="error"
          size="sm"
        />
        <div class="content-row">
          <div class="row-title">Experiment ID</div>
          <input v-model="experiment.id" />
        </div>
        <div class="content-row">
          <div class="row-title">Parameter</div>
          <input v-model="experiment.parameter" />
        </div>
        <div class="content-row">
          <div class="row-title">Default Value</div>
          <input v-model="experiment.default" />
        </div>
        <div class="content-row">
          <div class="row-title">Is Experiment Active</div>
          <input v-model="experiment.is_active" type="checkbox" />
        </div>
      </div>
    </div>

    <new-confirm-modal 
      :changes="changes"
      :isOpen="modalState"
      :onApprove="() => confirmSubmit()"
      :onReject="() => closeModal()"
      :mode="modalMode"
    />

  </div>
</template>

<script>
import { watch, computed, ref, onBeforeUnmount, onMounted, onBeforeMount } from "vue";
import { useStore } from "vuex";
import "v-calendar/dist/style.css";

import Button from "../../components/common/Button.vue";
import configSchema from "../../schemas/config";
import configConstants from "@/constants/configConstants";
import { useChangeTracker } from "../../composables/changeTracker";
import { useModal } from "../../composables/modal";
import NewConfirmModal from '../../components/common/NewConfirmModal.vue';

export default {
  name: "ServerConfig",
  components: {
    Button,
    NewConfirmModal
  },
  setup() {
    const store = useStore();
    const serverConfig = computed(
      () => store.getters["serverConfig/getServerConfig"]
    );
    const configObj = ref(null);

    const { openModal, closeModal, modalState, modalMode } = useModal();
    const { updateTrackerState, checkChanges, changes, isSync, onChangesSubmitted } = useChangeTracker("server-config", "", true);

    onBeforeMount(() => store.dispatch("serverConfig/getServerConfig"));
    onMounted(() => store.dispatch("loader/loadingStatus", false));
    onBeforeUnmount(() => store.dispatch("loader/loadingStatus", true));

    watch(
      () => serverConfig,
      (value) => {
        const tempVal = { ...value.value }
        if (Object.keys(tempVal).length === 0)
          return;
        configObj.value = tempVal;
        updateTrackerState(JSON.parse(JSON.stringify(configObj.value)));
      },
      { immediate: true }
    );

    const isLoading = ref(false);

    const exportTo = (environment) => {
      const setObj = {};
      Object.keys(configObj.value).forEach((key) => {
        if (configSchema[key] === "number") {
          setObj[key] = parseInt(configObj.value[key]);
        } else {
          setObj[key] = configObj.value[key];
        }
      });
      store.dispatch("serverConfig/exportServerConfig", {
        serverConfig: setObj,
        environment,
      });
    };

    const updateAddressableVersion = () => {
      const result = "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(
        /[xy]/g,
        function (c) {
          var r = (Math.random() * 16) | 0,
            v = c == "x" ? r : (r & 0x3) | 0x8;
          return v.toString(16);
        }
      );

      configObj.value.addressable_version = result;
    };

    const addExperiment = () => {
      configObj.value._experiments.push({
        id: "",
        parameter: "",
        default: "",
        is_active: false,
      });
    };

    const deleteExperiment = (index) => {
      configObj.value._experiments.splice(index, 1);
    };

    watch(isSync, (value) => {
      if (!value) {
        openModal(1);
      }
    });

    const submit = () => {
      updateTrackerState(configObj.value);
      checkChanges();
      openModal(0);
    };

    const confirmSubmit = () => {
      closeModal();
      if (modalMode.value === 1) {
        window.location.reload();
        return;
      }

      isLoading.value = true;
      const setObj = {};
      Object.keys(configObj.value).forEach((key) => {
        if (configSchema[key] === "number") {
          setObj[key] = parseInt(configObj.value[key]);
        } else {
          setObj[key] = configObj.value[key];
        }
      });
      store.dispatch("serverConfig/updateServerConfig", setObj).then(() => {
        onChangesSubmitted();
      }).finally(() => {
        isLoading.value = false;
      })
    };

    const appConfig = computed(() => store.getters["auth/getAppConfig"]);
    const env = appConfig.value.appEnv || "dev";

    return {
      configObj,
      configSchema,
      exportTo,
      env,
      configConstants,
      isLoading,
      updateAddressableVersion,
      addExperiment,
      deleteExperiment,
      submit,
      confirmSubmit,
      modalMode,
      modalState,
      closeModal,
      changes
    };
  },
};
</script>

<style scoped>
.main-container {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0 30px;
}

.content-container {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
}
.content-row {
  padding: 15px;
  width: calc(100% - 30px);
  display: flex;
  align-items: center;
}

.content-row-container {
  padding: 5px;
  display: flex;
  flex-direction: column;
}

.row-title {
  width: 40%;
  text-align: start;
}

.content-container.sub-container {
  border: 1px solid black;
}

.main-heading {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.flexible-row {
  display: inline-flex;
}

.experiment-container {
  border: 1px solid grey;
  border-radius: 5px;
  padding: 15px;
  margin: 10px;
  min-width: 300px;
}
</style>